<template>
    <div class="home">
        <div class="indexNav">
            <BanSwiper />
            <NewsSwiper />
        </div>
        <Study :data="indexData" v-if="indexData.Id > 0" />
        <Nav :data="recommendList" />
        <Dialog :dailogVisible="visible" :data="list" v-if="list.length > 0" />
        <!-- <SpecialDialog /> -->
    </div>
</template>
<script>
import BanSwiper from './banSwiper.vue'
import NewsSwiper from './newsSwiper'
import Study from './study.vue'
import Nav from './nav'
import Dialog from './Dialog.vue'
import SpecialDialog from './SpecialDialog.vue'

import { mapState } from 'vuex'
import { GetColumnByIds, GetDataList } from '@/api/common'
import { GetTokenByState, AsyncGetWxUserInfo } from '@/api/login'
export default {
    metaInfo() {
        return {
            title: this.siteInfo.SEOTitle ||  '文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: this.siteInfo.SEOKeyword ||  '文物出版社',
                },
                {
                    name: 'description',
                    content: this.siteInfo.SEODescription ||  '文物出版社',
                },
            ],
        }
    },
    components: {
        BanSwiper,
        NewsSwiper,
        Study,
        Nav,
        Dialog,
        SpecialDialog,
    },
    computed:{
        ...mapState({
            siteInfo:state => state.app.siteInfo,
        })
    },
    data() {
        return {
            subjectList: [],
            indexData: {},
            recommendList: [],

            visible: false,
            list: [],
            params: {
                columnId: 10033,
                pageIndex: 1,
                pageSize: 10,
                whereJson: { IsOpen: 'True' },
            },
        }
    },
    methods: {
        // async getColumn() {
        //     const { data } = await GetColumnByIds({ ids: '10027,10030,10026' })
        //     this.indexData = data
        // },
        async getRecommendList() {
            const { data } = await GetDataList({ columnId: 10036 })
            if (data.length > 0) {
                this.indexData = data[0]
                this.recommendList = data.slice(1)
            }
        },
        async getList() {
            const { data } = await GetDataList(this.params)
            this.list = data
            const isIgnore = JSON.parse(localStorage.getItem('isIgnore'))
            const day =
                '' +
                new Date().getFullYear() +
                (new Date().getMonth() + 1) +
                new Date().getDate()
            if (isIgnore == null || isIgnore !== day) {
                this.visible = true
            }
        },
    },
    created() {
        //this.getColumn()
        this.getList()
        this.getRecommendList()
    },
}
</script>
