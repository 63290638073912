<template>
    <div
        v-swiper:mySwiper="swiperOption"
        class="swiper-container"
        :class="list.length == 1 ? 'swiper-no-swiping' : ''"
    >
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in list" :key="item.id">
                <div class="ind-swiper-ban" style="background-color: #000f23">
                    <div class="bg">
                        <a
                            :href="item.LinkUrl"
                            :target="item.IsOpen ? '_blank' : ''"
                            rel="noopener noreferrer"
                        >
                            <img
                                :src="item.BackgroundImage"
                                :alt="item.Title"
                            />
                        </a>
                    </div>
                    <div class="inner">
                        <!-- <div v-html="item.Content"></div> -->
                        <!-- <div class="btn" data-swiper-parallax="-3000">
                            <a :href="item.LinkUrl" class="more" rel="noopener noreferrer"
                                :target="item.IsOpen ? '_blank' : ''">{{ item.Title }}</a>
                        </div> -->
                        <!-- <div class="img" data-swiper-parallax="-4500">
                            <img :src="item.Image" :alt="item.Title" />
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-pagination swiper_ban_pag"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
    </div>
</template>
<script>
import { GetDataList } from '@/api/common';
export default {
    data() {
        return {
            swiperOption: {
                loop: true,
                slidesPerView: 1,
                autoplay: true,
                delay: 6000,
                parallax: true,
                speed: 600,
                pagination: {
                    el: '.swiper_ban_pag',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
            list: [],
        };
    },
    methods: {
        getData() {
            GetDataList({ columnId: 10029 })
                .then(res => {
                    this.list = res.data;
                })
                .catch(err => err);
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped lang="less">
:deep(.swiper-button-prev) {
    left: 30px;
    color: #00ffc0;
    transform: translateY(-60px);
    background-color: rgba(0, 0, 0, 0.3);
    padding: 30px 20px;
    transition: 0.3s;
    &:hover {
        background-color: #000;
    }
    &::after {
        font-size: 24px;
        line-height: 1;
    }
}

:deep(.swiper-button-next) {
    right: 30px;
    color: #00ffc0;
    transform: translateY(-60px);
    background-color: rgba(0, 0, 0, 0.3);
    &::after {
        font-size: 24px;
        line-height: 1;
    }
    padding: 30px 20px;
    transition: 0.3s;
    &:hover {
        background-color: #000;
    }
}
</style>
