<template>
    <div class="newswiper" v-if="list.length > 0">
        <div class="auto-1440">
            <div class="label">
                <svg-icon class="icon_notice" icon-class="icon_notice"></svg-icon>
                <span>通知</span>
            </div>
            <div class="swiper" v-swiper:mySwiper="swiperOption" :class="list.length == 1 ? 'swiper-no-swiping':''">
                <div class="swiper-wrapper" >
                    <div class="swiper-slide" v-for="item in list" :key="item.Id">
                        <router-link :to=" { name:'noticeinfo',params:{id:item.Id} } ">{{item.Title}}</router-link>
                        <!-- <a class="dot" @click="goDetail(item)"></a> -->
                        <div class="time">{{item.UpdateTime.split(' ')[0].split('/').join('-')}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GetDataList } from '@/api/common'
export default {
    data(){
        return{
            list:[],
            swiperOption: {
                loop: true,
                slidesPerView: 1,
                autoplay:true,
                direction : 'vertical',
            },
        }
    },
    methods:{
        getNews(){
            GetDataList({columnId:10025}).then(res => {
                this.list = res.data
            }).catch(err => err)
        },
        goDetail(item){
            this.$router.push({name:'noticeinfo',params:{id:item.Id}})
        }
    },
    mounted(){
        this.getNews()
    }
}
</script>
<style scoped lang="less">
    .auto-1440{
        height: 60px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .icon_notice{
            font-size: 23px;
            margin-right: 10px;
        }
        .label{
            margin-right: 50px;
            display: flex;
            align-items: center;
            span{
                font-size: 18px;
                color: rgb(0, 255, 192);
                font-weight: bold;
            }

        }
    }
    .newswiper{
        z-index: 2;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(0, 0, 0 , .5);
    }
    .swiper{
        flex: 1;
        height: 60px;
    }
    .swiper-slide{
        display: flex;
        height: 60px !important;
        align-items: center;
        a{
            flex: 1;
            color: #fff;
            font-weight: bold;
            &:hover{
                color: rgb(0, 255, 192);
            }
        }
        .time{
            flex-shrink: 0;
            color: rgba(255, 255, 255, 0.502);
        }
    }

</style>