<template>
    <a-modal v-model="IsValidDialog" title="提示" @ok="handleOk">
        <p>暂无使用权限，请在题库购买相应专业后再次访问</p>
    </a-modal>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    data() {
        return {}
    },
    computed: {
        IsValidDialog: {
            get() {
                return this.$store.state.user.IsValidDialog
            },
            set(newVal) {
                this.$store.commit('user/setIsValid', newVal)
            },
        },
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    methods: {
        handleOk() {
            var url = this.userInfo.TokenUrl || 'http://wbtk.wbsjk.com/'
            console.log(url)
            window.open(url)
            this.$store.commit('user/setIsValid', false)
        },
    },
}
</script>
<style lang="less" scoped>
:deep(.ant-btn) {
    &:hover {
        color: #000;
        border-color: #79f9d9;
    }
}
:deep(.ant-btn-primary) {
    color: #000 !important;
}
</style>