<template>
    <div class="ind_nav">
        <template v-for="item in list">
            <div class="item" :key="item.id" :style="'background-image:url(' + item.Image + ');'">
                <a :href="item.LinkUrl">
                    <Title>
                        <div class="center">
                            <div class="cn">{{ item.Title }}</div>
                            <!-- <div class="en">{{ item.ColumnAlias }}</div> -->
                        </div>
                    </Title>
                    <div class="des dot8">
                        <pre>{{ item.Info }}</pre>
                    </div>
                    <div class="more">
                        <span>&lt;&lt; 查看详情 &gt;&gt;</span>
                    </div>
                </a>
            </div>
        </template>
    </div>
</template>
<script>
import Title from '@/components/index/Title'
const img1 = require('@/static/image/ind_nav01.jpg')
const img2 = require('@/static/image/ind_nav02.jpg')
export default {
    props: ['data'],
    components: {
        Title,
    },
    data() {
        return {
            list: [
            ],
        }
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                this.list = newVal.slice(0, 3)
            },
            immediate: true,
        },
    },
}
</script>
<style scoped lang="less">
.ind_nav {
    display: flex;

    .item {
        // width: 50%;
        flex: 1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 50px;

        &:not(:last-of-type) {
            margin-right: 50px;
        }

        a {
            display: block;
            padding: 70px 100px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 20px;
                top: 20px;
                bottom: 20px;
                right: 20px;
                border: 5px solid #00ffc0;
                opacity: 0;
                transition: 0.3s;
            }

            &:hover {
                &::before {
                    opacity: 1;
                }
            }
        }

        .center {
            margin-bottom: 40px;
        }

        .cn {
            font-size: 36px;
        }

        .des {
            // font-size: 16px;
            color: rgb(255, 255, 255);
            line-height: 1.667;
            height: 1.667 * 7em;
            margin-bottom: 20px;
        }

        .dot8 {
            -webkit-line-clamp: 7;
        }

        .more {
            text-align: center;
            color: #fff;
        }
    }
}
</style>
