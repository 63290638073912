<template>
    <div class="ind_study">
        <div class="auto-1440">
            <div class="title_wrap">
                <div class="title">
                    <Title>
                        <div class="left">
                            <div class="cn">{{ data.Title }}</div>
                            <div class="en">{{ data.EnTitle }}</div>
                        </div>
                    </Title>
                </div>
                <div class="des">
                    <pre>{{ data.Info }}</pre>
                </div>
            </div>
            <div
                class="list"
                v-swiper:mySwiper="swiperOption"
                :class="list.length == 3 ? 'swiper-no-swiping' : ''"
            >
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide"
                        v-for="item in list"
                        :key="item.Id"
                    >
                        <div class="info">
                            <div class="icon">
                                <img :src="item.Image" alt="" />
                            </div>
                            <div class="txt">
                                <h2 class="dot">{{ item.Title }}</h2>
                                <div class="des dot3">{{ item.Info }}</div>
                            </div>
                        </div>
                        <!-- study -->
                        <div class="links">
                            <template v-for="subitem in item.children">
                                <router-link
                                    v-for="link in subitem.children"
                                    :key="link.Id"
                                    :id="link.Id"
                                    :to="{
                                        name: 'learning-list',
                                        query: { subject: link.Id },
                                    }"
                                >
                                    <span class="dot2">{{ link.Title }}</span>
                                </router-link>
                            </template>
                        </div>
                    </div>
                </div>
                <div
                    class="swiper-pagination swiper-indstu-pag"
                    v-if="list.length > 3"
                ></div>
            </div>
        </div>
    </div>
</template>
<script>
// const imgs = [require('@/static/image/ind_study01.png'),require('@/static/image/ind_study02.png'),require('@/static/image/ind_study03.png')]
// const infos = ['本库中包含了责任设计师考试的必考科目、选考科目的全部考试大纲学习手册。','本库中包含了责任工程师考试的必考科目、选考科目的全部考试大纲学习手册。','本库中包含了责任监理师考试的必考科目、选考科目的全部考试大纲学习手册。']
import Title from '@/components/index/Title';
export default {
    props: ['data'],
    components: {
        Title,
    },
    data() {
        return {
            swiperOption: {
                loop: false,
                slidesPerView: 3,
                speed: 600,
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-indstu-pag',
                    clickable: true,
                },
            },
            list: [],
            loading: false,
        };
    },
    methods: {
        goDetail(item) {
            this.$router.push({
                name: 'learning',
                query: { subjectid: item.Id },
            });
        },
        // 获取考试科目
        async getSubjectList() {
            this.loading = true;
            let array = await this.$store.dispatch('model/getSubject');
            // array.forEach((item , i) => {
            //     item.Icon = imgs[i]
            //     item.Info = infos[i]
            // });
            console.log(array);
            this.list = array;
            this.loading = false;
        },
    },
    created() {
        this.getSubjectList();
    },
};
</script>
<style lang="less" scoped>
.ind_study {
    padding: 50px 0;
}

.title_wrap {
    display: flex;
    margin-bottom: 40px;

    .title {
        flex-shrink: 0;
        width: calc(33.33% + 10px);
    }

    .des {
        font-size: 18px;
        color: rgb(51, 51, 51);
        line-height: 1.667;
        text-align: justify;
    }
}

.swiper-slide {
    height: 465px;
    background-color: #fafafb;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.list {
    overflow: hidden;
}

.swiper-pagination {
    position: relative;
    padding-top: 60px;
}

/deep/.swiper-pagination-bullet {
    vertical-align: top;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    background-color: #00ffc0;
    opacity: 0.5;
}

/deep/.swiper-pagination-bullet-active {
    width: 30px;
    background-color: #00ffc0;
}

.info {
    padding: 50px 55px;

    .icon {
        width: 190px;
        height: 190px;
        position: relative;
        margin: 0 auto 25px;

        img {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
        }

        &::before {
            content: '';
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background-color: rgb(220, 253, 244);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .txt {
        text-align: center;

        h2 {
            font-size: 28px;
            color: rgb(0, 0, 0);
            font-weight: bold;
            // line-height: 1;
            margin-bottom: 25px;
        }

        .des {
            font-size: 18px;
            color: rgb(85, 85, 85);
            line-height: 1.667;
            height: 1.667 * 3em;
        }
    }
}

.links {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
    background-color: rgba(17, 38, 65, 0.8);
    opacity: 0;
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    padding: 40px;

    a {
        height: 80px;
        border: 2px solid rgba(255, 255, 255, 0.2);
        width: calc(50% - 0.2rem);
        text-align: center;
        margin: 5px 0;
        color: #fff;
        line-height: 1.33;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        transition: 0.3s;

        &:hover {
            border-color: #00ffc0;
            background-color: #00ffc0;
            color: #000;
        }
    }
}

.swiper-slide:hover {
    .links {
        opacity: 1;
    }
}
</style>
