<template>
    <a-modal v-model="visible" centered :footer="null" :width="1000" class="loginDialog" @cancel="closeLogin"
        :destroyOnClose="true">
        <a-spin :spinning="loading">
            <div v-swiper:mySwiper="swiperOption" class="swiper-container"
                :class="list.length == 1 ? 'swiper-no-swiping' : ''">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="item in list" :key="item.Id">
                        <div class="title">
                            <span>{{ item.Title }}</span>
                        </div>
                        <div class="dialog-container" v-html="item.Content"></div>
                    </div>
                </div>
                <div class="swiper-pagination swiper_notice_pag"></div>
                <div class="swiper-button-prev" slot="button-prev"><a-icon type="left-circle" /></div>
                <div class="swiper-button-next" slot="button-next"><a-icon type="right-circle" /></div>
            </div>
        </a-spin>
        <div class="dialog-footer">
            <a-checkbox @change="onChange">今日不再提示</a-checkbox>
        </div>
    </a-modal>
</template>
<script>
export default {
    props: ['dailogVisible', 'data'],
    data() {
        return {
            list: [],
            visible: false,
            params: {
                columnId: 10033,
                pageIndex: 1,
                pageSize: 10
            },
            loading: false,
            swiperOption: {
                loop: false,
                slidesPerView: 1,
                delay: 5000,
                parallax: true,
                spaceBetween: 100,
                speed: 600,
                pagination: {
                    el: '.swiper_notice_pag',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
        }
    },
    watch: {
        dailogVisible: {
            handler(newVal, oldVal) {
                this.visible = newVal
            },
            immediate: true
        },
        data: {
            handler(newVal, oldVal) {
                this.list = newVal
            },
            immediate: true
        }
    },
    methods: {
        closeLogin() {

        },
        onChange(e) {
            console.log(`checked = ${e.target.checked}`);
            const date = '' + new Date().getFullYear() + (new Date().getMonth() + 1) + (new Date().getDate())
            if (e.target.checked) {
                localStorage.setItem('isIgnore', JSON.stringify(date))
            } else {
                localStorage.removeItem('isIgnore')
            }
        },
        async getList() {
            this.loading = true
            const { data } = await this.GetDataList(this.params)
            this.list = data
            console.log(data);
            this.loading = false
        }
    },
    mounted() {
        // this.getList()
    }
}
</script>
<style scoped lang="less">
/deep/.ant-modal {
    width: 1000px !important;
}

.loginDialog {
    /deep/.ant-modal-body {
        padding: 60px 80px 50px;
        min-height: 610px;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    /deep/.ant-form-item-children {
        display: flex;
    }
}

.title {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
    flex-shrink: 0;

    span {
        padding-bottom: 26px;
        line-height: 1;
        position: relative;
        font-size: 32px;
        color: rgb(51, 51, 51);

        &::before {
            content: '';
            width: 100px;
            height: 5px;
            background-color: #00ffc0;
            border-radius: 2px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
        }
    }
}

.qrcode-btn {
    width: 80px;
    position: absolute;
    left: 5px;
    top: 5px;

    img {
        max-width: 100%;
        display: block;
        cursor: pointer;
    }

    &::before {
        content: '';
        width: 0;
        height: 0;
        border: (sqrt(80 * 80 * 2) / 2px) solid transparent;
        position: absolute;
        right: 0;
        top: 0;
        border-top-color: #fff;
        transform-origin: right top;
        transform: rotateZ(-45deg);
        z-index: 2;
    }
}

@media screen and (min-width: 1800px
    /*no*/
) and(max-height:800px

    /*no*/
) {
    /deep/.ant-modal-content {
        transform: scale(0.8);
    }
}

.dialog-container {
    font-size: 18px;
    line-height: 1.667;
    color: rgb(51, 51, 51);
    flex: 1;
}

.dialog-footer {
    flex-shrink: 0;
    text-align: center;
    font-size: 16px;
    color: rgb(136, 136, 136);
}

/deep/.ant-spin-nested-loading {
    flex: 1;
}

.ant-carousel /deep/.custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 30px;
    color: #666;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
    transition: .3s;

    &:hover {
        color: #333 !important;
    }
}

.ant-carousel /deep/.custom-slick-arrow:before {
    display: none;
}

.ant-carousel /deep/ .custom-slick-arrow:hover {
    opacity: 0.5;
}

.ant-carousel /deep/ .slick-slide h3 {
    color: #fff;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    display: none;
}

.swiper-button-prev,
.swiper-button-next {
    font-size: 40px;
    color: #00ffc0;
    // z-index: 50;
    transition: .3s;
}

.swiper-button-prev {
    left: -50px;
}

.swiper-button-next {
    right: -50px;
}

/deep/.swiper-container {
    // overflow: visible;
    position: static;
    overflow: hidden;

    .swiper-pagination-bullet {
        vertical-align: top;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #eee;
        opacity: 1;
        cursor: pointer;
        transition: .3s;

    }

    &:hover {
        .swiper-button-prev {
            left: 10px;
        }

        .swiper-button-next {
            right: 10px;
        }
    }

    .swiper-pagination-bullet-active {
        width: 30px;
        background-color: #00ffc0;
    }

    .swiper-pagination {
        font-size: 0;
        z-index: 20;
    }

    .swiper-pagination {
        // position: relative;
    }
}

/deep/.ant-spin-nested-loading {
    display: flex;
    flex-direction: column;

    .ant-spin-container {
        flex: 1;
        margin-bottom: 30px;
        overflow: hidden;
    }
}

/deep/.ant-checkbox-input:focus+.ant-checkbox-inner,
/deep/.ant-checkbox:focus::after,
/deep/.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner,
/deep/.ant-checkbox-wrapper:hover .ant-checkbox-inner,
/deep/.ant-checkbox-wrapper:focus .ant-checkbox-inner,
/deep/.ant-checkbox-checked::after,
/deep/.ant-checkbox:hover .ant-checkbox-inner {
    border-color: #00ffc0;
}

/deep/.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00ffc0;
    border-color: #00ffc0;
    box-shadow: unset;
}</style>